import { createSlice } from '@reduxjs/toolkit'

const systemStore = createSlice( {
    name: 'systemStore',
    initialState: {
        // language: navigator.language,
        language: '',
    },
    reducers: {
        //  更新-系统语言
        updateSystem( state, action ) {
            state.language = action.payload
        },
        //  清除-数据
        clearSystem( state ) {
            state.language = ''
        }
    }
} )
export const { updateSystem } = systemStore.actions
export default systemStore.reducer
