import React from 'react'
import i18n from 'i18next'

import { useTranslation } from 'react-i18next'
import { Row, Col, Button, Dropdown } from 'antd'
import moment from 'moment'
import 'moment/locale/zh-cn'

// store
import { store } from '@/store'
import { useDispatch } from 'react-redux'
import { updateSystem } from '@/store/modules/systemStore'
import './style.less'


function Footer() {
    moment.locale( 'zh-CN' )
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const menuList = [
        {
            label: 'English',
            key: 'en'
        },
        {
            label: '中文（简体）',
            key: 'zh-CN'
        },
        {
            label: '中文（繁体）',
            key: 'zh-TW'
        }
    ]
    const handleMenuClick = async ( e ) => {
        console.log( 'handleMenuClick' )
        const { key } = e
        dispatch( updateSystem( key ) )
        await i18n.changeLanguage( key )
    }

    const language = store.getState().systemStore.language

    return (
        <footer id="footer" className="dark">
            <Row className="bottom-bar">
                <Col xs={ 24 } sm={ 6 } md={ 6 } lg={ 12 } xl={ 8 }>
                    <div className="translate-button">
                        <Dropdown menu={{items: menuList,onClick: handleMenuClick}} trigger={['click']} placement="top">
                            <Button ghost size="small">{ t( 'footer.language' ) }</Button>
                        </Dropdown>
                    </div>
                </Col>
                <Col xs={ 24 } sm={ 18 } md={ 18 } lg={ 12 } xl={ 16 } style={ { textAlign: 'center' } }>
                    <div className="right-wrap translate-button">
                       <span className="right-style">
                           <a
                               href="/terms"
                               rel="noopener noreferrer"
                               target="_blank"
                           >
                              { t( 'footer.terms' ) }
                           </a>
                       </span>
                        <span className="right-style">
                            <a
                                href="/privacy"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                { t( 'footer.privacy' ) }
                            </a>
                        </span>
                        <span className="right-style">
                            <a
                                href="/agreement"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                { t( 'footer.msa' ) }
                            </a>
                        </span>
                        <span>
                            <a
                                href="/contactUs"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                { t( 'footer.contactUs' ) }
                            </a>
                        </span>
                        {/*<span style={ {marginRight: 12} }>ICP 证浙 B2-2-100257</span>
                         <span style={ {marginRight: 12} }>Copyright © 蚂蚁金融服务集团</span>*/ }
                    </div>
                </Col>
            </Row>
        </footer>
    )
}


export default Footer
