import api from '@/plugins/axios'
import MD5 from 'crypto-js/md5'
import { cloneDeep } from 'lodash-es'

// 登录
export const signIn = ( params ) => {
    const record = cloneDeep( params )
    record.password && ( record.password = MD5( record.password ).toString() )
    return api.post( '/v2/auth/login', record )
}

// 会员列表
export const productList = () => api.get( '/v2/product/list' )

// 会员权限
export const productEquity = () => api.get( '/v2/product/equity' )

// 购买会员
export const purchaseMembership = ( params ) => api.post( '/v2/order/create', params )
