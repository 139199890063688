import axios from 'axios'
import { store } from '@/store'
import { handleResponseData, httpCodeHandle } from './code.handle'

const instance = axios.create( {
    baseURL: '/api',
    timeout: 30000,
} )

// 添加请求拦截器
instance.interceptors.request.use( ( config ) => {
    // 在发送请求之前做些什么
    if ( config.headers ) {
        const language = store.getState().systemStore.language || navigator.language
        config.headers.Authorization = store.getState().userStore.token
        config.headers[ "Accept-Language" ] = language.replace( /-/g, "_" )
    }

    return config
}, ( error ) => {
    // 对请求错误做些什么
    console.error( '请求失败：', error )

    return Promise.reject( error )
} )

// 添加响应拦截器
instance.interceptors.response.use( ( response ) => {
    // 对响应数据做点什么
    return handleResponseData( response )

}, ( error ) => {
    // 对响应错误做点什么
    // 超出 2xx 范围的状态码都会触发该函数。
    return httpCodeHandle( error )
} )

export default instance
