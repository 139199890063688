import React from 'react'

const Correct = ()=> {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M13.3643 1.75725C13.7548 2.14777 13.7548 2.78094 13.3643 3.17146L6.3063 10.2294C6.30208 10.2338 6.29781 10.2382 6.2935 10.2425C6.14778 10.3882 5.96827 10.4795 5.78019 10.5165C5.46415 10.5787 5.12387 10.4874 4.87899 10.2425C4.8737 10.2372 4.86847 10.2319 4.86332 10.2265L0.636643 5.99983C0.246119 5.60931 0.246119 4.97614 0.636643 4.58562C1.02717 4.19509 1.66033 4.19509 2.05086 4.58562L5.58627 8.12104L11.9501 1.75725C12.3406 1.36672 12.9738 1.36672 13.3643 1.75725Z"
                  fill="#03AF00"/>
        </svg>
    )
}
export default Correct
