import React from 'react'
import PropTypes from 'prop-types'
import GitHubButton from 'react-github-button'
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import bannerPng from 'images/banner.png'
import downloadFile from '../../utils/download'

function Banner(props) {
    const {t} = useTranslation()

    return (
        <div className="banner-wrapper">
            { props.isMobile && (
                <TweenOne animation={ {opacity: 1} } className="banner-image-wrapper">
                    <div className="home-banner-image">
                        <img alt="banner" src={ bannerPng } width="100%"/>
                    </div>
                </TweenOne>
            ) }
            <QueueAnim className="banner-title-wrapper" type={ props.isMobile ? 'bottom' : 'right' }>
                <div key="line" className="title-line-wrapper">
                    <div
                        className="title-line"
                        style={ {transform: 'translateX(-64px)'} }
                    />
                </div>
                <h1 key="h1">{ t('banner.title') }</h1>
                <p key="content">{ t('banner.description') }</p>
                <div key="button" className="button-wrapper">
                    <Button
                        onClick={ downloadFile }
                        style={ {background: 'linear-gradient(135deg, #18BAFF, #1867FF)'} }
                        type="primary">{ t('banner.download') }</Button>
                    {/* <Button style={ {margin: '0 16px'} } type="primary" ghost>
                     开始使用
                     </Button>
                     <GitHubButton
                     key="github-button"
                     type="stargazers"
                     namespace="ant-design"
                     repo="ant-design-pro"
                     />*/ }
                </div>
            </QueueAnim>
            { !props.isMobile && (
                <TweenOne animation={ {opacity: 1} } className="banner-image-wrapper">
                    <img className="home-banner-anim" src={ bannerPng } alt="banner"/>
                </TweenOne>
            ) }
        </div>
    )
}

Banner.propTypes = {
    isMobile: PropTypes.bool.isRequired
}

export default Banner
