import api from '@/plugins/axios'


// 获取推荐账户详情
// export const referralAccounts = ( params ) => api.get( '/v2/recommend/referralAccounts', params )
export const referralAccounts = ( params ) => api.get( '/v2/recommend/referralAccounts', params )
//  提现申请
export const withdraw = ( params ) => api.post( '/v2/recommend/withdraw', params )
//  创建渠道代码
export const createCode = ( params ) => api.post( '/v2/recommend/createCode', params )

//  渠道管理
export const channelManager = ( params ) => api.post( '/v2/recommend/channelManager', params )

//  注册用户
export const registeredUser = ( params ) => api.post( '/v2/recommend/registeredUser', params )

//  推荐订单
export const recommendedOrder = ( params ) => api.post( '/v2/recommend/recommendedOrder', params )
