import React, { useContext, useState } from 'react'
import { Table, Spin, Modal } from 'antd'
import { Trans } from 'react-i18next'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'

// store
import { useSelector } from 'react-redux'

// provider
import { TranslationContext, TranslationProvider } from '../../provider/translationProvider'
// images
import Copy from '../../components/Icon/Copy'
import IdentificationSrc from '../../assets/images/users/identification.svg'
// Icon
import ResultSuccessIcon from '../../components/Icon/ResultSuccess'
import ResultErrorIcon from '../../components/Icon/ResultError'
// style
import './user.less'
//hook
import useCopyClipboard from '@/hooks/copyClipboard'
import { useServiceBundle } from './user.hook'
// 用户信息
const Identification = () => {
    const t = useContext( TranslationContext )
    const { copyClipboard } = useCopyClipboard()
    const isAffiliate = useSelector( ( state ) => state.userStore.isAffiliate )
    const affiliate = useSelector( ( state ) => state.userStore.affiliate )
    const userInfo = useSelector( ( state ) => state.userStore.userInfo )
    const orderInfo = useSelector( ( state ) => state.userStore.orderInfo )
    const expiredTime = ( new Date( orderInfo.expired_at * 1000 ) ).toLocaleString().replace( /\//g, "-" )
    return (
        <>
            <div className="identification">
                <div className="user-info">
                    <figure>
                        <img className="avatar"
                             src="https://client.file.jiashus.com/client-file/picture/74/617092099514322944.jpg"
                             alt=""/>
                        { orderInfo.is_expired && <figcaption>{ orderInfo.product_name }</figcaption> }
                    </figure>

                    <section>
                        <p>{ userInfo.email }</p>
                        <p>UID:{ userInfo.id } <span
                            onClick={ () => copyClipboard( userInfo.id ) }><Copy/></span></p>
                    </section>
                </div>
                {
                    orderInfo.is_expired && <time>
                        <img src={ IdentificationSrc } alt=""/> { t( 'user.expirationTime' ) } { expiredTime }
                    </time>
                }

            </div>
        </>

    )
}

// 会员套餐选择
const ServiceBundle = () => {
    const t = useContext( TranslationContext )
    const {
        serviceTab,
        productTabs,
        productData,
        productId,
        handleServiceTab,
        handleProduct,
        computedPrice,
        columns,
        dataSource,
        productLoading,
        tableLoading,
        handlePurchaseMembership
    } = useServiceBundle()
    return (
        <div className="service-bundle-container">
            <Spin spinning={ productLoading }>
                <div className="service-bundle">
                    {
                        productTabs.map( ( item, index ) => (
                            <span className={ serviceTab === index ? 'active' : '' } key={ item.type_name }
                                  onClick={ () => handleServiceTab( index ) }>{ item.name }</span> ) )

                    }
                </div>
                <div className="service-bundle-list">
                    {
                        productData.map( ( item, index ) => (
                            <div onClick={ () => handleProduct( item.id ) } className={ classnames( {
                                'service-bundle-list-item': true,
                                active: item.id === productId
                            } ) }
                                 key={ item.id }>
                                { item.optimal === 1 && <mark>{ t( 'user.mostPopular' ) }</mark> }
                                <h4>{ item.name }</h4>
                                <p>
                                <span>
                                    <em>¥</em>
                                    <strong>{ item.price || 0 }</strong>
                                </span>
                                    <label>{ computedPrice( item.price, item.days ) }/{ t( 'user.day' ) }</label>
                                </p>
                            </div>
                        ) )
                    }
                </div>

                <div className="service-bundle-buy">
                    <div className="btn" onClick={ handlePurchaseMembership }>{ t( 'user.buyNow' ) }</div>
                </div>

            </Spin>

            <div className="rights-interests">
                <strong>{ t( 'user.privileges' ) }</strong>
                <Table loading={ tableLoading } bordered dataSource={ dataSource } columns={ columns }
                       pagination={ false }/>
                <dl>
                    <dt>{ t( 'user.packageInfo' ) }</dt>
                    <dd>{ t( 'user.packageInfoFirst' ) }</dd>
                    <dd>{ t( 'user.packageInfoSecond' ) }</dd>
                    <dd>
                        <Trans
                            i18nKey="user.packageInfoThirdly"
                            components={ {
                                service: <a href="/agreement" rel="noopener noreferrer"
                                            target="_blank">{ t( 'footer.msa' ) }</a>,
                                privacy: <a href="/privacy" rel="noopener noreferrer"
                                            target="_blank">{ t( 'footer.privacy' ) }</a>,
                            } }
                        />
                    </dd>
                </dl>
            </div>

        </div>
    )
}

const ResultModel = () => {
    const t = useContext( TranslationContext )

    const location = useLocation()
    const searchParams = new URLSearchParams( location.search )
    const query = searchParams.get( 'result' )
    const [ isModalOpen, setIsModalOpen ] = useState( searchParams.has( 'result' ) )
    return (
        <Modal
            width={ '360px' }
            centered
            title={t('user.paymentResult')}
            open={ isModalOpen }
            footer={ null }
            onCancel={ () => setIsModalOpen( false ) }
        >
            <div className="user-result-model">
                {
                    query === 'success'
                        ? (
                            <>
                                <ResultSuccessIcon/>
                                <h3>{t('user.paymentSuccess')}</h3>
                                <p>{t('user.paymentSuccessTips')}</p>
                            </>
                        )
                        : (
                            <>
                                <ResultErrorIcon/>
                                <h3>{t('user.paymentCancelled')}</h3>
                            </>
                        )
                }
            </div>
        </Modal>
    )
}

const User = () => {
    return (
        <div className="container user-container">
            <TranslationProvider>
                <Identification/>
                <ServiceBundle/>
                <ResultModel/>
            </TranslationProvider>
        </div>
    )
}

export default User
